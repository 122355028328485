/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Header from 'shared/components/Header/Header';
import CloseIcon from '@mui/icons-material/Close';
import { FormSelect } from 'shared/components/Select/Select';
import { useQuery } from 'react-query';
import {
  DIGITAL_FAIR_VALUE_KEY,
  getChannels,
} from 'api/services/digitalFairValue';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { benchmarks } from '../benchmarks';
import { StyledTooltip } from '../styles';

function Step4() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { partialChannels: channels } = watch();

  const {
    fields: fullChannels,
    append: fullChannelAppend,
    remove: fullChannelRemove,
  } = useFieldArray({
    control,
    name: 'fullChannels',
  });

  const {
    fields: partialChannels,
    append: partialChannelsAppend,
    remove: partialChannelsRemove,
  } = useFieldArray({
    control,
    name: 'partialChannels',
  });

  const { data: fullAccessResponse } = useQuery(
    [DIGITAL_FAIR_VALUE_KEY, 'fullChannels'],
    () => getChannels('full')
  );
  const fullAccessChannels = (fullAccessResponse?.data.channels || []).map(
    (channel) => channel.channel
  );
  const { data: partialAccessResponse } = useQuery(
    [DIGITAL_FAIR_VALUE_KEY, 'partialChannels'],
    () => getChannels('partial')
  );
  const partialAccessChannels = partialAccessResponse?.data.channels || [];

  const renderFullAccessFields = (id: string, index: number) => {
    const getErrorMessage = (fieldName: string) => {
      if (!errors || !errors.channels) return '';
      // TODO
      // eslint-disable-next-line no-debugger, @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const channelErrors = errors?.fullChannels[index]?.[fieldName]?.message;
      return channelErrors ?? '';
    };
    return (
      <Grid container item xs={12} gap="1rem" key={id}>
        <Grid item xs={2.75}>
          <FormSelect
            fullWidth
            name={`fullChannels[${index}].channel`}
            control={control}
            options={fullAccessChannels}
            helperText={getErrorMessage('channel')}
            label={t('digitalFairValue.fullDataAccessTitle')}
          />
        </Grid>
        <Grid item xs={2.7}>
          <Box display="flex">
            <Controller
              control={control}
              name={`fullChannels[${index}].linkToAccountDashboard`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  helperText={getErrorMessage('linkToAccountDashboard')}
                  label={t('digitalFairValue.linkToAccountDashboard')}
                />
              )}
            />
            <StyledTooltip
              title={t('digitalFairValue.linkToAccountDashboardTooltip')}
            >
              <InfoOutlinedIcon color="action" />
            </StyledTooltip>
          </Box>
        </Grid>
        <Grid item xs={2.7}>
          <Box display="flex">
            <Controller
              control={control}
              name={`fullChannels[${index}].accessOwner`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('digitalFairValue.accessOwner')}
                  fullWidth
                  error={!!error}
                  helperText={getErrorMessage('accessOwner')}
                  type="email"
                />
              )}
            />
            <StyledTooltip title={t('digitalFairValue.accessOwnerTooltip')}>
              <InfoOutlinedIcon color="action" />
            </StyledTooltip>
          </Box>
        </Grid>
        <Grid item xs={2.7}>
          <Box display="flex">
            <Controller
              control={control}
              name={`fullChannels[${index}].digitalConsultant`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('digitalFairValue.digitalConsultant')}
                  fullWidth
                  error={!!error}
                  helperText={getErrorMessage('digitalConsultant')}
                  type="email"
                />
              )}
            />
            <StyledTooltip
              title={t('digitalFairValue.digitalConsultantTooltip')}
            >
              <InfoOutlinedIcon color="action" />
            </StyledTooltip>
          </Box>
        </Grid>

        <IconButton
          onClick={() => fullChannelRemove(index)}
          style={{ height: '40px', alignSelf: 'center' }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  const renderPartialAccessFields = (id: string, index: number) => {
    const getErrorMessage = (fieldName: string) => {
      if (!errors || !errors.channels) return '';

      const channelErrors =
        // TODO
        // eslint-disable-next-line no-debugger, @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errors?.partialChannels[index]?.[fieldName]?.message;
      return channelErrors ?? '';
    };
    const channel = channels[index];

    return (
      <Grid container item xs={12} gap="1rem" key={id}>
        <Grid item xs={2.75}>
          <FormSelect
            fullWidth
            name={`partialChannels[${index}].channel`}
            control={control}
            onChange={(e: any) => {
              setValue(`partialChannels[${index}].channel`, e.target.value);
              setValue(
                `partialChannels[${index}].benchmark`,
                partialAccessChannels.find((x) => x.channel === e.target.value)
                  ?.benchmark
              );
            }}
            options={partialAccessChannels.map((ch) => ch.channel)}
            helperText={getErrorMessage('channel')}
            label={t('digitalFairValue.partialDataAccessTitle')}
          />
        </Grid>
        {/* @ts-ignore */}
        {benchmarks[channel.benchmark || '']?.map(
          ({
            value,
            onChange,
          }: {
            value: string;
            label: string;
            onChange: any;
          }) => (
            <Grid item xs={2.75} key={value}>
              <Box display="flex">
                <Controller
                  control={control}
                  name={`partialChannels[${index}].${value}`}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      onChange={(e) => {
                        onChange(
                          setValue,
                          field.name,
                          e.target.value,
                          index,
                          channel
                        );
                      }}
                      helperText={getErrorMessage(value)}
                      label={t(`digitalFairValue.${value}`)}
                      type="number"
                    />
                  )}
                />
                {value === 'cpm' && (
                  <StyledTooltip title={t('digitalFairValue.cpmTooltip')}>
                    <InfoOutlinedIcon color="action" />
                  </StyledTooltip>
                )}
              </Box>
            </Grid>
          )
        )}
        <IconButton
          onClick={() => partialChannelsRemove(index)}
          style={{ height: '40px', alignSelf: 'center' }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };
  const addFullAccessChannel = () => {
    fullChannelAppend({
      id: Date.now(),
      channel: '',
      benchmark: '',
      linkToAccountDashboard: '',
      accessOwner: '',
      digitalConsultant: '',
    });
  };
  const addPartialAccessChannel = () => {
    partialChannelsAppend({
      id: Date.now(),
      channel: '',
      benchmark: '',
      impressions: '',
      clicks: '',
      cpm: '',
      cost: '',
      cpc: '',
      numberOfDays: '',
    });
  };

  return (
    <Grid container item rowGap="2rem" style={{ marginTop: '3rem' }}>
      <Header title={t('digitalFairValue.purchaseDetails')} />

      <Grid item xs={12} style={{ marginBottom: '2rem' }}>
        <Typography component="span">
          <strong>{t('digitalFairValue.fullDataAccessTitle')}</strong> -
          {t('digitalFairValue.fullDataAccessDesc')}
          <ul
            style={{ marginBlockStart: '0.2rem', paddingInlineStart: '20px' }}
          >
            <li>{t('digitalFairValue.fullDataAccessPoint1')}</li>
            <li>{t('digitalFairValue.fullDataAccessPoint2')}</li>
          </ul>
          <strong>{t('digitalFairValue.partialDataAccessTitle')}</strong> -
          {t('digitalFairValue.partialDataAccessDesc')}
          <br />
          {t('digitalFairValue.partialDataAccessDesc2')}
        </Typography>
      </Grid>

      <Grid container item xs={12} rowGap="2rem">
        <Grid container item xs={12} rowGap="2rem">
          {fullChannels.map((item, index) => (
            <Fragment key={item.id}>
              {renderFullAccessFields(item.id, index)}
            </Fragment>
          ))}
        </Grid>
        <Grid container item xs={12} rowGap="2rem">
          {partialChannels.map((item, index) => (
            <Fragment key={item.id}>
              {renderPartialAccessFields(item.id, index)}
            </Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={addFullAccessChannel}
          style={{
            borderRadius: '20px',
            marginRight: '2rem',
            border: '1px solid #447EEB',
            fontWeight: 700,
          }}
        >
          + {t('digitalFairValue.fullDataAccessTitle')}
        </Button>
        <Button
          variant="outlined"
          onClick={addPartialAccessChannel}
          style={{
            borderRadius: '20px',
            border: '1px solid #447EEB',
            fontWeight: 700,
          }}
        >
          + {t('digitalFairValue.partialDataAccessTitle')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Step4;
