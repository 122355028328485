import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ResetStepperFormDialog from 'shared/components/ResetStepperFormModal/ResetStepperFormDialog';

function Step2({ changeStep }: { changeStep: any }) {
  const { control, setValue, getValues } = useFormContext();
  const [resetDialog, setResetDialog] = useState<{
    show: boolean;
    text?: string;
  }>({
    show: false,
  });
  const { t } = useTranslation();

  return (
    <Grid container item sx={{ gap: '1rem' }} style={{ marginTop: '2rem' }}>
      {resetDialog.show && (
        <ResetStepperFormDialog
          open
          onClose={() => {
            setResetDialog({ show: false });
          }}
          onSuccess={() => {
            setValue('acceptDedAndInvoiceIssuedGranularly', 'false');
            setValue('acceptReceivingFullSalesFromThePartner', null);
            setValue('dashboardExcel', '');
            setValue('partialChannels', []);
            setValue('fullChannels', []);
            setValue('step', 1);
            changeStep(1);
          }}
          text={resetDialog.text}
        />
      )}
      <Grid item xs={12}>
        <Typography>
          {t('digitalFairValue.acceptDedAndInvoiceIssuedGranularly')}
        </Typography>
      </Grid>
      <Grid item xs={2.6} pl={4}>
        <Controller
          name="acceptDedAndInvoiceIssuedGranularly"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="acceptDedAndInvoiceIssuedGranularly-radio-buttons-group-label"
              style={{ justifyContent: 'space-between' }}
              onChange={(e, value) => {
                const values = getValues();
                const valuesAfterFilled =
                  values.acceptReceivingFullSalesFromThePartner !== null ||
                  values.dashboardExcel !== '';
                if (value === 'false' && valuesAfterFilled) {
                  setResetDialog({
                    show: true,
                    text: 'This will permanently clear all the data you inserted after this point in the form.',
                  });
                } else {
                  field.onChange(value);
                  setValue(field.name, value);
                }
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={t('yes')}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={t('no')}
              />
            </RadioGroup>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Step2;
