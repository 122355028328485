import { Stack, Grid, TextField, Slider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Select/Select';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import moment, { Moment } from 'moment';

import { Countries } from 'shared/enums';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ParametersFormData,
  useCountriesDataStore,
} from 'store/countriesDataStore';
import { shallow } from 'zustand/shallow';
import { useQuery } from 'react-query';
import { DIVISIONS_KEY, getDivisions } from 'api/services/divisions';
import {
  CAMPAIGNS_KEY,
  getCampaignAttributes,
  getCampaignTypes,
  getTouchpoints,
} from 'api/services/campaigns';
import { Touchpoints } from 'shared/types';
import { DATE_FORMATS } from 'shared/constants';
import { getAdditionalFormFieldsEnv } from 'services/env';
import { debounce, isEmpty, range } from 'lodash';
import { useTotalBudgetStore } from 'store/totalBudgetStore';
import {
  BudgetHelperText,
  Buttons,
  CalculateButton,
  CampaignDatePicker,
  ClearButton,
  FirstRow,
  HeaderWrapper,
  SecondRow,
  StyledCard,
  StyledTooltip,
  ThirdRow,
  TouchpointsHeader,
} from './styles';
import useValidationSchema from './useValidationSchema';
import Header from '../Header/Header';

interface ParametersFormProps {
  country: Countries;
  touchpoints?: Touchpoints[];
  onSubmit: (data: FieldValues) => void;
}

function ParametersForm({
  country,
  touchpoints = ['youtube', 'meta', 'tiktok'],
  onSubmit,
}: ParametersFormProps) {
  const { t } = useTranslation();
  const additionalFormFields = getAdditionalFormFieldsEnv();

  const formData = useCountriesDataStore(
    (state) => state[country].formData,
    shallow
  );
  const [updateForm, resetCountryData] = useCountriesDataStore(
    (state) => [state[country].updateForm, state[country].reset],
    shallow
  );
  const resetTotalBudget = useTotalBudgetStore(
    (state) => state.resetTotalBudget,
    shallow
  );

  const validationSchema = useValidationSchema();

  const { control, handleSubmit, setValue, reset, watch } =
    useForm<ParametersFormData>({
      defaultValues: {
        touchpoints,
        campaignStartDate: moment(),
      },
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
    });

  const [
    divisionName,
    campaignTypeName,
    campaignStartDate,
    campaignDuration,
    campaignBumper,
  ] = watch([
    'divisionName',
    'campaignTypeName',
    'campaignStartDate',
    'campaignDuration',
    'campaignBumper',
  ]);

  const { data: divisionsResponse } = useQuery(DIVISIONS_KEY, () =>
    getDivisions(country)
  );
  const isCampaignTypesEnabled = !!divisionName;
  const { data: campaignTypesResponse } = useQuery({
    queryKey: [CAMPAIGNS_KEY, 'campaign-types', divisionName],
    queryFn: () => getCampaignTypes(divisionName, country),
    enabled: isCampaignTypesEnabled,
  });

  const isAdditionalParamsEnabled = !!divisionName && !!campaignTypeName;
  const { data: touchpointsResponse } = useQuery({
    queryKey: [CAMPAIGNS_KEY, 'touchpoints', divisionName, campaignTypeName],
    queryFn: () => getTouchpoints(divisionName, campaignTypeName, country),
    enabled: isAdditionalParamsEnabled,
  });

  const { data: campaignAttributesResponse } = useQuery({
    queryKey: [
      CAMPAIGNS_KEY,
      'campaign-attributes',
      divisionName,
      campaignTypeName,
      country,
    ],
    queryFn: () =>
      getCampaignAttributes(divisionName, campaignTypeName, country),
    enabled: isAdditionalParamsEnabled,
  });

  const getDisabledProps = (condition: boolean) => ({
    disabled: !condition,
  });
  const campaignDurationOptions = useMemo(() => {
    if (!campaignAttributesResponse?.data.campaignAttributes) return [];
    const { campaignDurationMin, campaignDurationMax } =
      campaignAttributesResponse.data.campaignAttributes;
    return range(campaignDurationMin, campaignDurationMax + 1).map((d) =>
      d.toString()
    );
  }, [campaignAttributesResponse]);

  useEffect(() => {
    if (isEmpty(formData)) {
      reset();
      return;
    }
    Object.entries(formData).forEach(([key, value]) => {
      setValue(key as keyof typeof formData, value);
    });
  }, [formData, reset, setValue]);

  useEffect(() => {
    if (!campaignAttributesResponse?.data.campaignAttributes) return;
    const { bumperRecommended, campaignDurationMax, campaignDurationMin } =
      campaignAttributesResponse.data.campaignAttributes;
    const scaledBumperRecommended = bumperRecommended
      ? bumperRecommended * 100
      : 0;

    if (!campaignDuration) {
      setValue('campaignDuration', campaignDurationMax);
    } else if (
      campaignDuration >= campaignDurationMin &&
      campaignDuration <= campaignDurationMax
    ) {
      setValue('campaignDuration', campaignDuration);
    } else {
      setValue('campaignDuration', null);
    }

    const endDate = moment(campaignStartDate).add(campaignDuration, 'w');
    setValue('campaignEndDate', endDate);
    setValue('campaignBumper', campaignBumper || scaledBumperRecommended);
    updateForm({
      ...formData,
      campaignDuration: campaignDuration || campaignDurationMin,
      campaignBumper: campaignBumper || scaledBumperRecommended,
      campaignEndDate: endDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignAttributesResponse,
    setValue,
    updateForm,
    campaignDuration,
    campaignStartDate,
  ]);

  const handleChange = (
    value: string | number | null | undefined,
    name: string
  ) => {
    updateForm({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    resetCountryData();
    resetTotalBudget(country);
  };

  const renderCampaignBumperValue = (value: number) => {
    return <div>{value}%</div>;
  };

  const scaledBumperParams = useMemo(() => {
    if (!campaignAttributesResponse?.data.campaignAttributes) return null;
    const { bumperMin, bumperMax, bumperRecommended } =
      campaignAttributesResponse.data.campaignAttributes;

    return {
      bumperMin: bumperMin && bumperMin !== bumperMax ? bumperMin * 100 : 0,
      bumperMax: bumperMax && bumperMin !== bumperMax ? bumperMax * 100 : 100,
      bumperRecommended: bumperRecommended ? bumperRecommended * 100 : 0,
    };
  }, [campaignAttributesResponse]);

  const campaignBumperMarks = useMemo(() => {
    if (!scaledBumperParams?.bumperMin || !scaledBumperParams.bumperMax)
      return [];
    return [
      {
        value: scaledBumperParams.bumperMin,
        label: `${scaledBumperParams.bumperMin}%`,
      },
      {
        value: scaledBumperParams.bumperMax,
        label: `${scaledBumperParams.bumperMax}%`,
      },
    ];
  }, [scaledBumperParams]);

  const shouldShowTouchpoint = useCallback(
    (touchpoint: Touchpoints) =>
      (touchpointsResponse?.data[touchpoint] &&
        divisionName &&
        campaignTypeName) ||
      !divisionName ||
      !campaignTypeName,
    [campaignTypeName, divisionName, touchpointsResponse?.data]
  );

  return (
    <StyledCard>
      <HeaderWrapper>
        <Header title={t('calculator.campaignParameters')} />
      </HeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <FirstRow container item>
            <Grid item xs>
              <FormSelect
                fullWidth
                name="divisionName"
                control={control}
                onChange={(event) => {
                  updateForm({
                    ...formData,
                    campaignTypeName: '',
                    divisionName: event.target.value as string,
                  });
                }}
                options={divisionsResponse?.data.divisions || []}
                label={t('calculator.form.divisionName')}
              />
            </Grid>
            <Grid item xs>
              <FormSelect
                fullWidth
                name="campaignTypeName"
                control={control}
                onChange={(event) =>
                  updateForm({
                    ...formData,
                    campaignDuration: null,
                    campaignTypeName: event.target.value as string,
                  })
                }
                options={campaignTypesResponse?.data.campaignTypes || []}
                label={t('calculator.form.campaignTypeName')}
                {...getDisabledProps(isCampaignTypesEnabled)}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name="campaignStartDate"
                control={control}
                render={({ field }) => (
                  <CampaignDatePicker
                    format={DATE_FORMATS.BY_SPACE}
                    label={t('calculator.form.campaignStartDate')}
                    disablePast
                    {...field}
                    onChange={(date) => {
                      updateForm({
                        ...formData,
                        campaignStartDate: date as Moment,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              {additionalFormFields && (
                <>
                  <Controller
                    name="budget"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        type="number"
                        label={t('calculator.form.budget')}
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        onChange={(event) =>
                          handleChange(event.target.value as string, 'budget')
                        }
                      />
                    )}
                  />

                  <BudgetHelperText>{t('optional')}</BudgetHelperText>
                </>
              )}
              <Stack direction="row" gap="3rem">
                <Grid item xs>
                  <FormSelect
                    fullWidth
                    name="campaignDuration"
                    control={control}
                    onChange={debounce(
                      (event) =>
                        handleChange(
                          event.target.value as string,
                          'campaignDuration'
                        ),
                      300
                    )}
                    renderValue={(value) => `${value} ${t('weeksShort')}`}
                    options={campaignDurationOptions}
                    label={t('calculator.form.campaignDuration')}
                    {...getDisabledProps(
                      isCampaignTypesEnabled &&
                        campaignDurationOptions.length > 1
                    )}
                  />
                </Grid>

                <Grid item xs>
                  <Controller
                    name="campaignEndDate"
                    control={control}
                    render={({ field }) => (
                      <CampaignDatePicker
                        format={DATE_FORMATS.BY_SPACE}
                        label={t('calculator.form.campaignEndDate')}
                        disabled
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Stack>
            </Grid>
          </FirstRow>
          <TouchpointsHeader
            variant="subtitle2"
            textTransform="uppercase"
            fontWeight="600"
          >
            {t('touchPoints.label')}
          </TouchpointsHeader>
          <SecondRow container item>
            {touchpoints.map(
              (touchpoint) =>
                shouldShowTouchpoint(touchpoint) && (
                  <Grid key={touchpoint} item xs>
                    <Stack direction="row">
                      <FormSelect
                        fullWidth
                        name={`${touchpoint}TargetGroup`}
                        control={control}
                        {...getDisabledProps(isAdditionalParamsEnabled)}
                        onChange={(event) =>
                          handleChange(
                            event.target.value as string,
                            `${touchpoint}TargetGroup`
                          )
                        }
                        options={touchpointsResponse?.data[touchpoint] || []}
                        label={t(`calculator.form.${touchpoint}TargetGroup`)}
                      />
                      {additionalFormFields && (
                        <StyledTooltip title="tooltip">
                          <InfoOutlinedIcon color="secondary" />
                        </StyledTooltip>
                      )}
                    </Stack>
                  </Grid>
                )
            )}
            {campaignAttributesResponse?.data.campaignAttributes
              .bumperRecommended && (
              <Grid item xs sx={{ marginTop: '-22px' }}>
                <Stack>
                  <Stack direction="row">
                    <Typography
                      variant="subtitle2"
                      fontWeight="600"
                      color="textLight"
                      sx={{ marginBottom: '10px' }}
                    >
                      {t('calculator.form.campaignBumper')}
                    </Typography>
                    {isAdditionalParamsEnabled &&
                      campaignAttributesResponse?.data.campaignAttributes
                        .bumperMax ===
                        campaignAttributesResponse?.data.campaignAttributes
                          .bumperMin && (
                        <StyledTooltip
                          title={t('calculator.form.bumperTooltip')}
                        >
                          <InfoOutlinedIcon
                            color="secondary"
                            fontSize="small"
                          />
                        </StyledTooltip>
                      )}
                  </Stack>
                  <Controller
                    name="campaignBumper"
                    control={control}
                    render={({ field }) => (
                      <Slider
                        disabled={
                          !isAdditionalParamsEnabled ||
                          !scaledBumperParams?.bumperMin ||
                          !scaledBumperParams?.bumperMax
                        }
                        step={1}
                        min={scaledBumperParams?.bumperMin}
                        max={scaledBumperParams?.bumperMax}
                        defaultValue={scaledBumperParams?.bumperRecommended}
                        valueLabelDisplay="on"
                        {...field}
                        onChange={(_, value) =>
                          handleChange(value as number, `campaignBumper`)
                        }
                        marks={campaignBumperMarks}
                        valueLabelFormat={renderCampaignBumperValue}
                        sx={{
                          padding: '15px 0',
                          marginBottom: '0',
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )}
          </SecondRow>
          <ThirdRow container item>
            <Buttons item xs>
              <ClearButton variant="text" onClick={handleReset}>
                {t('clearForm')}
              </ClearButton>
              <CalculateButton type="submit" variant="contained">
                {t('calculator.calculate')}
              </CalculateButton>
            </Buttons>
          </ThirdRow>
        </Grid>
      </form>
    </StyledCard>
  );
}

export default ParametersForm;
