/* eslint-disable camelcase */
import { Moment } from 'moment';
import { create } from 'zustand';

export type FullDataAccessChannel = {
  channel: string;
  linkToAccountDashboard: string;
  accessOwner: string;
  digitalConsultant: string;
};

export type WithoutFullDataAccessChannel = {
  benchmark: string;
  channel: string;
  impressions: number;
  cpm: string;
  cost: number;
  numberOfDays: number;
  cpc: number;
  clicks: number;
};

export interface DigitalFairValueFormData {
  divisions: { id: number; title: string }[];
  brands: { id: number; title: string }[];
  partner: string;
  startDate: Moment | null;
  endDate: Moment | null;
  step: number;
  acceptDedAndInvoiceIssuedGranularly: string | null;
  acceptReceivingFullSalesFromThePartner: string | null;
  dashboardExcel: string;
  partialChannels: WithoutFullDataAccessChannel[];
  fullChannels: FullDataAccessChannel[];
  currentStep: number;
}

interface StoreDigitalFairValue {
  formData: DigitalFairValueFormData;
  updateForm: (newForm?: DigitalFairValueFormData) => void;
  resetForm: () => void;
}

export const useDigitalFairValueFormStore = create<StoreDigitalFairValue>(
  (set) => ({
    formData: {} as DigitalFairValueFormData,
    updateForm: (newForm?: DigitalFairValueFormData) =>
      set((state) => ({
        ...state,
        formData: newForm || ({} as DigitalFairValueFormData),
      })),
    resetForm: () => {
      set((state) => ({
        ...state,
        formData: {
          divisions: [],
          brands: [],
          partner: '',
          startDate: null,
          endDate: null,
          step: 0,
          currentStep: 0,
          acceptDedAndInvoiceIssuedGranularly: null,
          acceptReceivingFullSalesFromThePartner: null,
          dashboardExcel: '',
          partialChannels: [],
          fullChannels: [],
        } as DigitalFairValueFormData,
      }));
    },
  })
);
