import { debounce } from 'lodash';

const debouncedSetValue = (
  key: string,
  value: number | string,
  setValue: any
) => debounce(() => setValue(key, value), 700);

export const benchmarks = {
  cpm: [
    {
      value: 'impressions',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.cpm > 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cost`,
            ((Number(value) / 1000) * Number(channel.cpm)).toFixed(2),
            setValue
          )();
        }
        if (channel.cost > 0 && channel.cpm <= 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cpm`,
            (Number(channel.cost) / (Number(value) / 1000)).toFixed(2),
            setValue
          )();
        }
      },
    },
    {
      value: 'cpm',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.impressions > 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cost`,
            ((Number(channel.impressions) / 1000) * Number(value)).toFixed(2),
            setValue
          )();
        }
        if (channel.cost > 0 && Number(value) > 0 && channel.impressions <= 0) {
          debouncedSetValue(
            `partialChannels[${index}].impressions`,
            Math.trunc((Number(channel.cost) / Number(value)) * 1000),
            setValue
          )();
        }
      },
    },
    {
      value: 'cost',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.impressions > 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cpm`,
            (Number(value) / (Number(channel.impressions) / 1000)).toFixed(2),
            setValue
          )();
        }
        if (channel.cpm > 0 && Number(value) > 0 && channel.impressions <= 0) {
          debouncedSetValue(
            `partialChannels[${index}].impressions`,
            Math.trunc((Number(value) / Number(channel.cpm)) * 1000),
            setValue
          )();
        }
      },
    },
  ],
  cpc: [
    {
      value: 'clicks',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);

        if (channel.cpc > 0 && Number(value) > 0)
          debouncedSetValue(
            `partialChannels[${index}].cost`,
            (Number(value) * channel.cpc).toFixed(2),
            setValue
          )();
        if (channel.cost > 0 && channel.cpc <= 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cpc`,
            (channel.cost / Number(value)).toFixed(2),
            setValue
          )();
        }
      },
    },
    {
      value: 'cpc',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.clicks > 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cost`,
            (channel.clicks * Number(value)).toFixed(2),
            setValue
          )();
        }
        if (channel.cost > 0 && Number(value) > 0 && channel.clicks <= 0) {
          debouncedSetValue(
            `partialChannels[${index}].clicks`,
            Math.trunc(channel.cost / Number(value)),
            setValue
          )();
        }
      },
    },
    {
      value: 'cost',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.clicks > 0 && Number(value) > 0) {
          debouncedSetValue(
            `partialChannels[${index}].cpc`,
            (Number(value) / channel.clicks).toFixed(2),
            setValue
          )();
        }
        if (channel.cpc > 0 && Number(value) > 0 && channel.clicks <= 0) {
          debouncedSetValue(
            `partialChannels[${index}].clicks`,
            Math.trunc(Number(value) / channel.cpc),
            setValue
          )();
        }
      },
    },
  ],
  'cpm-e': [
    {
      value: 'numberOfDays',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
    {
      value: 'cost',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
  ],
  ff: [
    {
      value: 'cost',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
  ],
};
