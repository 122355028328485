/* eslint-disable @typescript-eslint/ban-ts-comment */
import Header from 'shared/components/Header/Header';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DigitalFairValueFormData,
  useDigitalFairValueFormStore,
} from 'store/digitalFairValueFormStore';
import { Grid } from '@mui/material';

import { shallow } from 'zustand/shallow';
import { useEffect, useRef, useState } from 'react';
import {
  ClearButton,
  CalculateButton,
} from 'shared/components/ParametersForm/styles';
import moment from 'moment';
import { useMutation } from 'react-query';
import { generateReport, getCheckDocStatus } from 'api/services/generatePdf';
import { DATE_FORMATS } from 'shared/constants';
import { isEmpty } from 'lodash';
import DownloadFileWidget from 'shared/components/DownloadFileWidget/DownloadFileWidget';
import { useTranslation } from 'react-i18next';
import { verifyDigitalFairValue } from 'api/services/digitalFairValue';
import ResetStepperFormDialog from 'shared/components/ResetStepperFormModal/ResetStepperFormDialog';
import { StyledCard, HeaderWrapper } from '../FairValue/styles';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import { benchmarks } from './benchmarks';
import GenerateReportDialog from './components/GenerateReportDialog';
import { useValidationSchema } from './validationSchemas';

function DigitalFairValue() {
  const { t } = useTranslation();
  const intervalId = useRef('');
  const lastFilePath = useRef('');
  const [dialogStep, setDialogStep] = useState<
    'accept' | 'done' | 'loading' | 'reject' | ''
  >('');
  const [rejectParameters, setRejectParameters] = useState([]);
  const [rejectText, setRejectText] = useState<React.ReactNode>(
    <div>
      The data you supplied does not fulfill L&apos;Oreal internal requirements.
      <br />
      Check the parameters.
    </div>
  );
  const [resetDialog, setResetDialog] = useState({
    show: false,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const validationSchema = useValidationSchema();

  const formData = useDigitalFairValueFormStore(
    (state) => state.formData,
    shallow
  );

  const resetForm = useDigitalFairValueFormStore(
    (state) => state.resetForm,
    shallow
  );

  const {
    data: verifyData,
    mutate: verify,
    isLoading,
  } = useMutation(verifyDigitalFairValue);

  const methods = useForm<DigitalFairValueFormData>({
    defaultValues: {
      divisions: [],
      brands: [],
      partner: '',
      startDate: null,
      endDate: null,
      step: currentStep,
      acceptDedAndInvoiceIssuedGranularly: null,
      acceptReceivingFullSalesFromThePartner: null,
      dashboardExcel: '',
      partialChannels: [],
      fullChannels: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    setError,
    setValue,
    getValues,
    formState,
    trigger,
    watch,
  } = methods;

  const [fullChannels, partialChannels] = watch([
    'fullChannels',
    'partialChannels',
  ]);

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]) => {
      setValue(key as keyof typeof formData, value);
    });
  }, [formData, setValue, trigger]);

  const onGeneratePdf = async () => {
    await generateReport({
      transactionId: verifyData!.data!.transactionId,
      type: 'digital-fair-value',
    });
    setDialogStep('loading');

    async function checkDocStatus() {
      const result = await getCheckDocStatus(verifyData!.data!.transactionId!);
      if (result?.data?.status === 'done') {
        clearInterval(intervalId.current);
        setDialogStep('done');
        lastFilePath.current = result.data.filePath;
      }
    }

    const id = setInterval(() => {
      checkDocStatus();
    }, 3000);
    intervalId.current = `${id}`;
  };

  const onReportDownload = () => {
    if (!lastFilePath.current) return;

    const anchor = document.createElement('a');
    anchor.href = lastFilePath.current;
    anchor.target = '_blank';
    anchor.download = 'report';
    anchor.rel = 'noreferrer';
    // Append the anchor to the body, trigger click, then remove it
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const getApiBenchmark = (
    benchmark: string,
    field: string | number,
    fieldName: string
  ) => {
    // @ts-ignore
    return benchmarks[benchmark].some((x) => x.value === fieldName)
      ? Number(field)
      : undefined;
  };

  const onSubmit = async (data: DigitalFairValueFormData) => {
    const values = getValues();

    if (
      (values.step === 1 || values.step === 2) &&
      (values.acceptDedAndInvoiceIssuedGranularly === 'false' ||
        values.acceptReceivingFullSalesFromThePartner === 'false')
    ) {
      setDialogStep('reject');
      return;
    }

    verify({
      brandNames: data.brands.map(({ title }) => title),
      divisionNames: data.divisions.map(({ title }) => title),
      partnerName: data.partner,
      startDate: moment(data.startDate).format(DATE_FORMATS.BY_HYPHEN),
      endDate: moment(data.endDate).format(DATE_FORMATS.BY_HYPHEN),
      campaignPeriod: `${moment(data.startDate).format(
        'DD MMM YYYY'
      )} - ${moment(data.endDate).format('DD MMM YYYY')}`,
      isDedIssued: Boolean(data.acceptDedAndInvoiceIssuedGranularly),
      isFullSalesDataReceived: Boolean(
        data.acceptReceivingFullSalesFromThePartner
      ),
      dashboardExcel: data.dashboardExcel,
      fullAccessChannels: !isEmpty(data.fullChannels)
        ? data.fullChannels?.map((channel, i: number) => ({
            id: i.toString(),
            channelName: channel.channel,
            dashboardLink: channel.linkToAccountDashboard,
            accessOwner: channel.accessOwner,
            digitalConsultant: channel.digitalConsultant,
          }))
        : undefined,
      partialAccessChannels: !isEmpty(data.partialChannels)
        ? data.partialChannels?.map((channel, i: number) => ({
            id: i.toString(),
            channelName: channel.channel,
            cost: getApiBenchmark(channel.benchmark, channel.cost, 'cost'),
            impr: getApiBenchmark(
              channel.benchmark,
              channel.impressions,
              'impressions'
            ),
            cpm: getApiBenchmark(channel.benchmark, channel.cpm, 'cpm'),
            cpc: getApiBenchmark(channel.benchmark, channel.cpc, 'cpc'),
            numberOfDays: getApiBenchmark(
              channel.benchmark,
              channel.numberOfDays,
              'numberOfDays'
            ),
            clicks: getApiBenchmark(
              channel.benchmark,
              channel.clicks,
              'clicks'
            ),
          }))
        : undefined,
      currentStep: Math.min(4, currentStep + 1),
    });

    setCurrentStep((step) => step + 1);
    setValue('step', currentStep + 1);
  };

  useEffect(() => {
    if (isLoading || currentStep <= 2) return;
    if (
      currentStep >= 4 &&
      verifyData?.data &&
      isEmpty(verifyData?.data.errors)
    ) {
      setDialogStep('accept');
    }
    if (currentStep >= 4 && verifyData?.data.errors) {
      setRejectText(
        <div>
          The data you supplied does not fulfill L&apos;Oreal internal
          requirements.
          <br />
          Check the parameters for the following channels:
          <ul>
            {verifyData?.data.errors.map(({ channelName, fields }) =>
              channelName ? (
                <li key={channelName}>{channelName}</li>
              ) : (
                fields?.map((prop) => (
                  <li key={prop}>{t(`digitalFairValue.${prop}`)}</li>
                ))
              )
            )}
          </ul>
        </div>
      );
      setDialogStep('reject');
    }
    verifyData?.data.errors?.forEach(({ rowId }) => {
      if (partialChannels[Number(rowId)]) {
        // @ts-ignore
        benchmarks[partialChannels[Number(rowId)].benchmark].forEach(
          ({ value }: { value: string }) => {
            setError(`partialChannels[${rowId}].${value}` as any, {
              type: 'custom',
              message: '',
            });
          }
        );
      }
    });
  }, [
    currentStep,
    isLoading,
    setError,
    verifyData?.data,
    verifyData?.data.errors,
  ]);

  const clearForm = () => {
    resetForm();
    reset();
    setCurrentStep(0);
    setValue('step', 0);
  };

  const onError = (errors: any, e: any) => {
    console.error('Form submission failed');
    console.error(errors);
  };

  const handleCloseDialog = () => {
    setDialogStep('');
  };

  const handleCloseResetDialog = () => {
    setResetDialog({ show: false });
  };

  return (
    <StyledCard style={{ height: '100%' }}>
      <DownloadFileWidget
        fileUrl="https://media-calculator-static.s3.eu-west-1.amazonaws.com/download/E-COMMERCE-BRIEF-FAIR-VALUE.xlsx"
        title={t('digitalFairValue.downloadDebrief')}
      />
      {resetDialog.show && (
        <ResetStepperFormDialog
          open
          onClose={handleCloseResetDialog}
          onSuccess={clearForm}
        />
      )}
      {dialogStep && (
        <GenerateReportDialog
          open
          step={dialogStep}
          rejectParameters={rejectParameters}
          rejectText={rejectText}
          onClose={handleCloseDialog}
          onGeneratePdf={onGeneratePdf}
          onDownload={onReportDownload}
        />
      )}
      <HeaderWrapper>
        <Header title={t('digitalFairValue.generalInformation')} />
      </HeaderWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Step1 />
          {currentStep >= 1 && <Step2 changeStep={setCurrentStep} />}
          {currentStep >= 2 && <Step3 changeStep={setCurrentStep} />}
          {currentStep >= 3 && <Step4 />}
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ClearButton
              variant="text"
              onClick={() => {
                setResetDialog({
                  show: true,
                });
              }}
            >
              {t('clearForm')}
            </ClearButton>
            <CalculateButton
              type="submit"
              variant="contained"
              disabled={
                currentStep === 3 &&
                isEmpty(fullChannels) &&
                isEmpty(partialChannels)
              }
            >
              {t('next')}
            </CalculateButton>
          </Grid>
        </form>
      </FormProvider>
    </StyledCard>
  );
}

export default DigitalFairValue;
